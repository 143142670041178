h2 {
    font-size: 2.5vh !important;
    font-weight: bold !important;
}

h3 {
    font-size: 2.25vh !important;
    font-weight: bold !important;
}

h4 {
    font-size: 2vh !important;

}

a:link {
    text-decoration: none;
}


a:visited {
    text-decoration: none;
}


a:hover {
    text-decoration: none;
}


a:active {
    text-decoration: none;
}

.navLink {
    font-weight: 900;
    height: 100%;
    display: block;
    padding-left: 28px;
    padding-right: 28px;
    padding-top: 28px;
    font-size: 2vh;
    padding-bottom: 28px;
    height: 25px;
    border-radius: 0px !important;
    color: black;
    text-decoration: none;
}

.navLinkActive {
    color: #dd3f3c;
}

.navLink:hover {
    border-radius: 0.25em;
    color: #dd3f3c;
    background-color: #e6e6e6;


}

.boxxx {
    border: 1px solid #dd3f3c !important;
}

.MuiButton-root {
    text-transform: none !important;
    color: white !important;
    background-color: #919191 !important;
    border-color: #919191 !important;
    border-radius: 20px !important;
    margin-left: 2px !important;
    margin-right: 2px !important;
}


.MuiButton-root:hover {
    background-color: #dd3f3c !important;
    border-color: #dd3f3c !important;
}



.burger-button {
    text-decoration: none !important;
    color: black !important;
    background-color: transparent !important;
}




.activeButton {
    background-color: #dd3f3c !important;
    border-color: #dd3f3c !important;
}



.noPadMargin {
    padding-left: 0px !important;
    padding-right: 0px !important;
}




body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: rgb(119, 119, 119);
    font-size: 1.5vh;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}


.notLikeALink {
    color: black !important;

}



.notLikeALink:link {
    color: black !important;
    text-decoration: none;
}


.notLikeALink:visited {
    color: black !important;
    text-decoration: none;
}


.notLikeALink:hover {
    color: black !important;
    text-decoration: none;
}


.notLikeALinka:active {
    color: black !important;
    text-decoration: none;
}

footer {
    position: fixed;
    padding: 10px 10px 0px 10px;
    margin-right: auto;
    margin-left: auto;
    bottom: 0;
    width: 70%;
    text-align: center;
    /* Height of the footer*/
    height: 40px;
    /* background: grey; */
    /* Footer height */
}



.Electric {
    background-color: lightpink !important
}


.Fridge {
    background-color: lightblue !important
}

.Gas {
    background-color: lightyellow !important
}

.Coffee {
    background-color: #FCD5B4 !important
}


.DivElectric {
    background-color: lightpink !important
}


.DivFridge {
    background-color: lightblue !important
}

.DivGas {
    background-color: lightyellow !important
}

.DivCoffee {
    background-color: #FCD5B4 !important
}

.MuiTab-root {
    color: #dd3f3c !important
}

.Mui-selected {}

/* 
MuiTab-root 
MuiTab-textColorPrimary
Mui-selected */




@media only screen and (max-device-width: 480px) {

    h2 {
        font-size: 6vw !important;
    }

    h3 {
        font-size: 6vw !important;
    }


    h4 {
        font-size: 4vw !important;

    }
}



pre {
    white-space: pre-wrap;
    /* css-3 */
    white-space: -moz-pre-wrap;
    /* Mozilla, since 1999 */
    white-space: -pre-wrap;
    /* Opera 4-6 */
    white-space: -o-pre-wrap;
    /* Opera 7 */
    word-wrap: break-word;
    /* Internet Explorer 5.5+ */
}

        

.MuiTable-stickyHeader{
    height: auto;
}

.headerClassName > div > div{
    text-align: right !important;
} 